import React from "react"
import Layout from '../components/layout'

import VDPModalContainer from "../components/vdp/VDPModalContainer"
import VDPHeader from "../components/vdp/VDPHeader"
import VDPSlides from "../components/vdp/VDPSlides"
import VDPFinancing from "../components/vdp/VDPFinancing"
import VDPAbout from "../components/vdp/VDPAbout"
import VDPDescription from "../components/vdp/VDPDescription"
import VDPOptions from "../components/vdp/VDPOptions"
import VDPRecommended from "../components/vdp/VDPRecommended"

import { configurableString } from "../Modules/configurableVehicleInfo"
import { ModalVehicleProvider } from "../components/modal/storage/VehicleContext"
import InnerHTML from 'dangerously-set-html-content'

import style from "../components/vdp/vdp.module.scss"
export default function Vehicle(props) {
  const { hideRecommended,
    vehicle, AutoCheckEnabled, similarRVs, theVDPSlug, theVDPseo, financeButtons, fallbackText, showSpecs, showTrim,
    unavailableImg, disclaimer, disclaimerCalculator, disclaimerContact, disclaimerFinanceApp, vehiclecardbutton,HideNewUsed,
    decimalSpaceHide, site_id, lead_url, gtmId
  } = props.pageContext
  const { topHtmlVDP, botHtmlVDP } = props.pageContext
  const altVDPContent = props.pageContext.hasOwnProperty('altVDPContent') ? props.pageContext.altVDPContent : null
  const altInventory = altVDPContent !== null ? true : false

  const netPriceCustomText = props.pageContext?.netPriceCustomText || props.pageContext?.inventoryManagement?.netPriceCustomText || 'Net Price'
  
  console.log(altVDPContent)

  const { months, downpayment, rates } = props.pageContext

  const vdpSEO = { seo: theVDPseo }

  vdpSEO.seo.MetaDesc = configurableString(vdpSEO.seo.MetaDesc, vehicle.VehicleInfo)
  vdpSEO.seo.MetaDesc = (vdpSEO.seo.MetaDesc == "" ? "Vehicle Description" : vdpSEO.seo.MetaDesc)

  vdpSEO.seo.MetaTitle = configurableString(vdpSEO.seo.MetaTitle, vehicle.VehicleInfo)
  vdpSEO.seo.MetaTitle = (vdpSEO.seo.MetaTitle == "" ? "Vehicle Description" : vdpSEO.seo.MetaTitle)

  const renderBody = () => (
    <>
      <ModalVehicleProvider>
        <VDPModalContainer
          siteData={{
            lead_url, site_id, gtmId,
            disclaimerContact, disclaimerFinanceApp, disclaimerCalculator
          }}
          financeData={{ rates, downpayment, defaultMonth: months }}
          vehicleInfo={{ ...vehicle }}
        />

      <main className={style["main"]}>
        <VDPSlides vehicle={vehicle} unavailableImg={unavailableImg} />
        <VDPFinancing vehicle={vehicle}
          replacePrice={altVDPContent !== null && altVDPContent.VDP.overwritePrice}
          newPriceText={altVDPContent !== null && altVDPContent.VDP.newPriceText}
          buttons={ altVDPContent !== null && altVDPContent.VDP.overwriteCTAs
          ? altVDPContent.VDP.VDPSettings 
          : financeButtons}
          AutoCheckEnabled={AutoCheckEnabled}
          disclaimer={disclaimer} financing={{ months, downpayment, rates }}
          leadURL={lead_url} siteID={site_id}
          fallbackText={fallbackText}/>
        <VDPAbout vehicle={vehicle} />
        <VDPDescription vehicle={vehicle} />
        <VDPOptions vehicle={vehicle} />
      </main>
      { hideRecommended ? <></> :<VDPRecommended recommendation={similarRVs} slug={theVDPSlug} netPriceCustomText={netPriceCustomText}
        AutoCheckEnabled={AutoCheckEnabled}  decimalSpaceHide={decimalSpaceHide} buttons={vehiclecardbutton} unavailableImg={unavailableImg} 
        fallbackText={fallbackText} showSpecs={showSpecs} showTrim={showTrim}/> }
      </ModalVehicleProvider> 
    </>
  )

  return (
    <Layout seo={vdpSEO}>
      <div className={style["body"]}>
        <div className={style["page-wrap"]}>
        <InnerHTML html={configurableString((altVDPContent !== null && altVDPContent.VDP.disableVDPTopHtml
            ? (altVDPContent.VDP.topHtmlStaticVDP !== undefined ? altVDPContent.VDP.topHtmlStaticVDP : typeof topHtmlVDP === 'undefined' ? "" : topHtmlVDP)
            : typeof topHtmlVDP === 'undefined' ? "" : topHtmlVDP), vehicle.VehicleInfo)} />
          <VDPHeader vehicle={vehicle} showTrim={showTrim} HideNewUsed={HideNewUsed}/>
          {renderBody()}
          <InnerHTML html={configurableString((altVDPContent !== null && altVDPContent.VDP.disableVDPBottomHtml
            ? (altVDPContent.VDP.botHtmlStaticVDP !== undefined ? altVDPContent.VDP.botHtmlStaticVDP : typeof topHtmlVDP === 'undefined' ? "" : topHtmlVDP)
            : typeof botHtmlVDP === 'undefined' ? "" : botHtmlVDP), vehicle.VehicleInfo)} />
        </div>
        <div className="sr-disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }} />
        <data id="page" value="Vehicle Display Page"></data>
        <data id="vin" value={vehicle.VehicleInfo.VIN}></data>
        <data id="stock" value={vehicle.VehicleInfo.StockNumber}></data>
        <data id="condition" value={vehicle.VehicleInfo.IsNew ? 'New' : 'Used'}></data>
        <data id="price" value={vehicle.Pricing.ExtraPrice1}></data>
        <data id="year" value={vehicle.VehicleInfo.Year}></data>
        <data id="make" value={vehicle.VehicleInfo.Make}></data>
        <data id="model" value={vehicle.VehicleInfo.Model}></data>
        <data id="trim" value={vehicle.VehicleInfo.Trim}></data>
        <data id="color" value={vehicle.VehicleInfo.ExteriorColor}></data>
        <data id="category" value={vehicle.VehicleInfo.BodyType}></data>
        <data id="fuel" value={vehicle.VehicleInfo.EngineFuelType}></data>
        <data id="type" value="vehicle"></data>
        <data id="category" value={vehicle.VehicleInfo.BodyType}></data>
        <data id="date" value={vehicle.VehicleInfo.InStockDate}></data>
      </div>
    </Layout>
  )
}

Vehicle.defaultProps = {
  pageContext: {
    months: 0,
    rate: 0,
    downpayment: 0,
  }
}
